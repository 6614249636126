<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>REP SETTINGS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <p>Rep = Dave Zwickel</p>
        </div>
      </v-col>
    </v-row>

    <v-row class="sc-title mt-7">
      <v-col md="10" sm="6">
        <div>
          <h1>Settings Schedule</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Block' })"
            >Block</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-row class="sc-title mt-7">
      <v-col md="4" sm="4">
        <v-select
          v-model="targetItems"
          :items="targetItems_values"
          label="WEEKLY EVALUATIONS TARGET"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="reset = false"
          >
            Add Day Time
          </v-btn>
        </div>
      </template>

      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Add Day Time</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-select
                              label="Day of week"
                              required
                              v-model="addDayTime.day_of_week"
                              :rules="[(v) => !!v || 'This field is required']"
                              :items="day_of_week_table_data.weekDays"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <date-time
                              :time="true"
                              v-model="addDayTime.time"
                              :reset="reset"
                            >
                            </date-time>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="day_of_week_table_data.headers"
          :items="day_of_week_table_data.data"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item.delete="{ item }">
            <v-icon
              v-if="candelete === 'true'"
              class="cursor-pointer"
              @click="deleteItem(item)"
              color="error"
            >
              mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="start_of_day_table_data.headers"
          :items="start_of_day_table_data.data"
          class="elevation-1 cursor-pointer"
          hide-default-footer
        >
          <template v-slot:item.address="{ item }">
            <v-text-field
              v-model="item.address"
              dense
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.city="{ item }">
            <v-text-field v-model="item.city" dense hide-details></v-text-field>
          </template>

          <template v-slot:item.state="{ item }">
            <v-text-field
              v-model="item.state"
              dense
              hide-details
            ></v-text-field>
          </template>

          <template v-slot:item.zip="{ item }">
            <v-text-field v-model="item.zip" dense hide-details></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="end_of_day_table_data.headers"
          :items="end_of_day_table_data.data"
          class="elevation-1 cursor-pointer"
          hide-default-footer
        >
          <template v-slot:item.address="{ item }">
            <v-text-field
              v-model="item.address"
              dense
              hide-details
            ></v-text-field>
          </template>

          <template v-slot:item.city="{ item }">
            <v-text-field v-model="item.city" dense hide-details></v-text-field>
          </template>

          <template v-slot:item.state="{ item }">
            <v-text-field
              v-model="item.state"
              dense
              hide-details
            ></v-text-field>
          </template>

          <template v-slot:item.zip="{ item }">
            <v-text-field v-model="item.zip" dense hide-details></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTime from "../../components/DateTime/DateTime";

export default {
  components: { DateTime },
  data() {
    return {
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      dialog: false,
      valid: false,
      reset: false,
      start_of_day_table_data: {
        headers: [
          { text: "START OF DAY", value: "start_of_day" },
          { text: "Address", value: "address", class: "fuTypes" },
          { text: "City", value: "city", class: "fuTypes" },
          { text: "ST", value: "state", class: "countryZip" },
          { text: "Zip", value: "zip", class: "countryZip" },
        ],
        data: [
          {
            start_of_day: "Mon",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Tues",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Weds",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Thurs",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Friday",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
        ],
      },
      end_of_day_table_data: {
        headers: [
          { text: "END OF DAY", value: "start_of_day" },
          { text: "Address", value: "address", class: "fuTypes" },
          { text: "City", value: "city", class: "fuTypes" },
          { text: "ST", value: "state", class: "countryZip" },
          { text: "Zip", value: "zip", class: "countryZip" },
        ],
        data: [
          {
            start_of_day: "Mon",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Tues",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Weds",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Thurs",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
          {
            start_of_day: "Friday",
            address: "7500 Blue Ridge Blvd",
            city: "Kansas City",
            state: "MO",
            zip: "64138",
          },
        ],
      },
      day_of_week_table_data: {
        headers: [
          { text: "Day of Week", value: "day_of_week" },
          { text: "Time", value: "time" },
          { text: "DELETE", value: "delete" },
        ],
        data: [
          {
            day_of_week: "Monday",
            time: "01:11 AM",
          },
          {
            day_of_week: "Wednesday",
            time: "03:11 PM",
          },
        ],
        weekDays: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      targetItems: "15",
      targetItems_values: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
      ],
      addDayTime: {
        day_of_week: "",
        time: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.day_of_week_table_data.data.push(this.addDayTime);
        this.dialog = false;
        this.addDayTime = { day_of_week: "", time: "" };
        this.reset = true;
      }
    },
    deleteItem(item) {
      console.log("ITEM IS HERE TO BE DELETED");
      const myIndex = this.day_of_week_table_data.data.indexOf(item);
      myIndex > -1
        ? this.day_of_week_table_data.data.splice(myIndex, 1)
        : false;
    },
  },
};
</script>

<style type="text/css">
.fuTypes {
  width: 25%;
}

.countryZip {
  width: 15%;
}
</style>
